import React from "react"
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image"
import { Layout } from "components/layout"
import { DefaultHeader } from "components/headers/default-header"
import { Seo } from "components/seo"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons"
import MapContainer from "components/map-container"
import { graphql } from "gatsby"

const items = [
  { title: "Company Name", content: "X-HACK Corporation" },
  {
    title: "Location",
    content:
      "〒107-0062 3F-251 Minowa Omotesando, 3-15-9 Minami-Aoyama, Minato-ku, Tokyo",
  },
  { title: "Representative Director", content: "Shinsuke Matsuda" },
  { title: "capital stock", content: "1,000,000 yen" },
  { title: "Establishment", content: "March 14, 2018" },
  {
    title: "Business",
    content: `Development and operation of Twitter instant win PARKLoT\nInternet Service Business\nConsulting Business`,
  },
  {
    title: "Access",
    content:
      "Tokyo Metro Ginza Line Hanzomon Line Omotesando Exit A4, 3 min. walk",
  },
]

const Company = ({ location, data }) => {
  const {
    allMemberJson: { nodes: members },
  } = data
  return (
    <>
      <DefaultHeader lang="en-US" />
      <Layout lang="en-US">
        <Seo
          pagetitle="About Us"
          pagepath={location.pathname}
          pagedesc="About Us | PARKLoT offers a Twitter campaign tool that can be run as many times as you want for a monthly fee."
        />
        <main className="information">
          <section className="information__heading py-10 px-4 md:pt-14 md:pb-16 md:px-0">
            <div className="container">
              <h1 className="text-white font-bold">
                Corporate Philosophy, Company Information, Members
              </h1>
            </div>
          </section>
          <section className="information__philosophy bg-white">
            <div className="container py-10 px-4 md:py-14 md:px-0">
              <h2 className="text-xl md:text-2xl text-title py-2 pl-6 mb-6 relative md:pl-8 md:mb-12">
                Corporate Philosophy
              </h2>
              <h3 className="text-title font-bold mb-4 md:mb-6">
                Empowering Society with the Power of IT
              </h3>
              <p className="text-text md:text-xl">
                Our mission is to empower everyone who works in the world.
                <br />
                Remote work is now commonplace, and as a result, software for
                information sharing and business efficiency is needed more than
                ever.
                <br />
                It is highly likely that the Internet society will further
                develop, and a society in which working place, time, language,
                and nationality will no longer be limited.
                <br />
                As Japan's working population shrinks, Japanese companies are
                under pressure to make major changes to enable a diverse
                workforce.
                <br />
                Through the provision of software, we will continue to enable
                diverse ways of working, and furthermore, we will develop a
                system in the world to empower people who work.
              </p>
            </div>
          </section>
          <section className="information__about bg-white">
            <div className="container py-10 px-4 md:py-14 md:px-0">
              <h2 className="text-xl md:text-2xl text-title py-2 pl-6 mb-6 relative md:pl-8 md:mb-12">
                Company Information
              </h2>
              <table className="border-collapse w-full border-t border-lightgrey border-b mb-6 md:mb-12">
                <tbody>
                  {items.map((item, index) => {
                    return (
                      <tr key={index} className="border-b border-lightgrey">
                        <th className="text-text block px-3 pt-5 pb-2 md:pt-6 md:pb-6 md:w-4/12 md:table-cell">
                          {item.title}
                        </th>
                        <td className="text-text whitespace-pre-wrap px-3 pb-5 block md:table-cell md:pt-6 md:pb-6">
                          {item.content}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
              <MapContainer />
            </div>
          </section>
          <section className="information__members bg-white">
            <div className="container py-10 px-4 md:py-14 md:px-0">
              <h2 className="text-xl md:text-2xl text-title py-2 pl-6 mb-6 relative md:pl-8 md:mb-12">
                メンバー
              </h2>
              <div className="flex w-full flex-col gap-14 md:flex-row md:flex-wrap md:gap-20">
                {members.map(member => {
                  return (
                    <div key={member.id} className="information__member-photo">
                      <GatsbyImage
                        image={getImage(member.image)}
                        alt={member.name}
                        className="mb-6 rounded h-60"
                      />
                      <p className="text-title text-sm mb-1">
                        {member.position}
                      </p>
                      <p className="font-bold text-title text-xl mb-2">
                        {member.name}
                      </p>
                      <p className="text-text">{member.comment}</p>
                    </div>
                  )
                })}
              </div>
            </div>
          </section>
          <section className="information__cta bg-primary">
            <div className="container py-10 px-4 md:py-14 md:px-0">
              <h2 className="cta text-white text-center mb-4 font-bold">
                SNS campaign tools&nbsp;
                <br />
                to accelerate follower acquisition&nbsp;
                <br />
                and sales promotion
              </h2>
              <p className="text-white text-center md:text-xl mb-8">
                We are also available for consultation&nbsp;
                <br className="block md:hidden" />
                regarding the content of your campaign plan.
                <br />
                Please feel free to contact us.
              </p>
              <div className="rounded-lg bg-white py-6 px-4 md:p-10">
                <div className="m-auto mb-4 text-center w-11/12 md:w-7/12">
                  <StaticImage
                    layout="constrained"
                    alt="Documentation of PARKLoT features in 3 minutes"
                    src="../../images/company/comp_doc.png"
                  />
                </div>
                <div className="m-auto mb-8 md:mb-14 text-center">
                  <a
                    className="btn-features_contact border text-base md:text-2xl hover:text-accent hover:bg-white hover:border"
                    href="https://hub.park-lot.com/document-download"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Download a 3-minute document to learn&nbsp;
                    <br />
                    about PARKLoT's features(free)
                  </a>
                </div>
                {/* その他お問合せ */}
                <div className="flex flex-col gap-4 md:flex-row md:justify-between md:gap-14 items-stretch text-center">
                  <div className="rounded-lg bg-palegrey p-4 md:py-6 md:px-8 w-full flex items-center flex-col justify-center">
                    <h4 className="font-bold text-xl text-text mb-3">
                      Contact us by phone
                    </h4>
                    <p className="mb-3">
                      <a
                        href="tel:0368210143"
                        className="text-2xl text-accent eng-num font-bold"
                      >
                        <FontAwesomeIcon
                          icon={faPhoneAlt}
                          className="text-accent inline-block mr-2"
                        />
                        050-3528-8481
                      </a>
                    </p>
                    <p className="text-xs text-text">
                      (9:00 - 19:00 except Saturdays, Sundays, and holidays)
                    </p>
                  </div>
                  <div className="rounded-lg bg-palegrey p-4 md:py-6 md:px-8 w-full">
                    <h4 className="font-bold text-xl text-text mb-3">
                      Free Online Consultation
                    </h4>
                    <a
                      className="bg-primary text-white font-bold py-3 border px-6 inline-block mb-3 rounded hover:text-primary hover:bg-white hover:border"
                      href="https://hub.park-lot.com/free-consulting"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      Check available appointment times
                    </a>
                    <p className="text-xs text-text">
                      Please use&nbsp;
                      <a
                        href="https://hub.park-lot.com/contact"
                        target="_blank"
                        rel="noreferrer noopener"
                        className="underline text-accent"
                      >
                        this form&nbsp;
                      </a>
                      to inquire about collaboration
                      <br />
                      with our services or to propose a product or service.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </Layout>
    </>
  )
}

export const query = graphql`
  query GetMembers {
    allMemberJson {
      nodes {
        id
        name
        position
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
        comment
      }
    }
  }
`

export default Company
